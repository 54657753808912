import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Seaside Escape Yacht Charters
			</title>
			<meta name={"description"} content={"Convierta sus sueños en realidadn"} />
			<meta property={"og:title"} content={"Seaside Escape Yacht Charters"} />
			<meta property={"og:description"} content={"Convierta sus sueños en realidadn"} />
			<meta property={"og:image"} content={"https://vibrantpanacea.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vibrantpanacea.com/img/6643593.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vibrantpanacea.com/img/6643593.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vibrantpanacea.com/img/6643593.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vibrantpanacea.com/img/6643593.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vibrantpanacea.com/img/6643593.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vibrantpanacea.com/img/6643593.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline1"
					lg-text-align="center"
					md-text-align="left"
				>
					Sobre nosotros
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Bienvenido a Seaside Escape Yacht Charters, donde ofrecemos una amplia gama de servicios de primera calidad diseñados para mejorar su tiempo en el agua. Tanto si busca una escapada tranquila, una salida de aventura o un evento memorable, nuestros servicios se adaptan a todas sus necesidades. Nuestro compromiso con la excelencia y la atención al detalle garantizan que cada momento con nosotros sea extraordinario.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://vibrantpanacea.com/img/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Nuestra flota y servicios
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Nuestra impresionante flota de yates está equipada con las mejores comodidades para garantizar su confort y disfrute. Cada yate se mantiene meticulosamente y cuenta con interiores de lujo, tecnología moderna y un amplio espacio para relajarse. Desde íntimos veleros hasta grandes yates a motor, tenemos la embarcación perfecta para satisfacer sus necesidades. Nuestros servicios incluyen.
					</Text>
				</Box>
				<Image src="https://vibrantpanacea.com/img/5.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://vibrantpanacea.com/img/6.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Experiencias personalizadas
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Alquiler de yates privados: Ideal para vacaciones familiares, escapadas románticas o aventuras en grupos pequeños. Disfrute de la privacidad y flexibilidad de su propio yate, adaptado a sus horarios y preferencias.
						<br />
						<br />
    Excursiones de un día: Explore las aguas cercanas y disfrute de un día de sol, mar y relax.
						<br />
						<br />
    Charters nocturnos: Prolongue su aventura con una estancia de una noche, con todas las comodidades del hogar.
						<br />
						<br />
    Viajes largos: Zarpe en un viaje de varios días para explorar destinos más lejanos.
						<br />
						<br />
Charters para eventos: Perfectos para celebrar ocasiones especiales como bodas, aniversarios, cumpleaños o eventos corporativos.
						<br />
						<br />
    Bodas: Intercambie votos rodeado de la impresionante belleza del mar abierto.
						<br />
						<br />
    Retiros corporativos: Organice reuniones, actividades de formación de equipos o entretenimiento para clientes en un entorno único e inspirador.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://vibrantpanacea.com/img/7.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://vibrantpanacea.com/img/8.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Contacte con nosotros para más información
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Y esto es sólo el principio. Descubra toda nuestra oferta poniéndose en contacto con nosotros hoy mismo. Nuestro personal amable y bien informado está listo para responder a cualquier pregunta y ayudarle a planificar su experiencia de alquiler de yates perfecta. Llámenos o envíenos un correo electrónico para obtener información más detallada y comenzar a planificar su aventura.
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="11px 44px 12px 44px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="36px"
					letter-spacing="1px"
				>
					Contáctenos
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});